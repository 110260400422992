import { styled } from '@mui/material/styles';

const LogoWrapper = styled('div')(() => ({
  '& svg': {
    width: '208px',
    height: '60px',
  },
}));

export default LogoWrapper;
