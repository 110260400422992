import { styled } from '@mui/material/styles';

const LoginItemWrapper = styled('div')(({ theme }) => ({
  cursor: 'pointer',
  position: 'relative',
  width: '412px',
  height: '356px',
  backgroundColor: theme.palette.background.cardExtraLayer,
  padding: theme.spacing(4),
  borderRadius: theme.spacing(2.5),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2.5),
  backgroundSize: 'cover',
  justifyContent: 'flex-end',

  ':after': {
    borderRadius: 'inherit',
    height: '100%',
    width: '100%',
    content: '""',
    top: 0,
    position: 'absolute',
    background:
      'linear-gradient(180deg, rgba(26, 51, 118, 0.00) 17.49%, #1A3376 70.51%)',
    opacity: 0,
    transition: 'opacity 900ms linear',
  },

  ':before': {
    borderRadius: 'inherit',
    height: '100%',
    width: '100%',
    content: '""',
    top: 0,
    position: 'absolute',
    background:
      'linear-gradient(180deg, rgba(34, 67, 155, 0.00) 17.49%, #22439B 70.51%)',
    opacity: 1,
    transition: 'opacity 250ms linear',
  },

  '&:hover': {
    ':after': {
      transitionDuration: '250ms',
      opacity: 1,
    },

    ':before': {
      transitionDuration: '900ms',
      opacity: 0,
    },

    '.MuiButtonBase-root': {
      backgroundColor: theme.palette.primary.hover,
    },
  },
}));

export default LoginItemWrapper;
