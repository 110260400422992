import { Grid, Typography } from '@mui/material';
import { useRouter } from 'next/navigation';
import { useEffect } from 'react';

import LoginContainer, { LoginWrapper } from './styles/LoginContainer';
import CommonButton from '@/components/common/CommonButton';
import { Footer } from '@/components/common/Footer';
import Logo from '@/components/icons/Logo';
import LoginItemWrapper from '@/components/pages/Login/styles/LoginItemWrapper';
import LogoWrapper from '@/components/pages/Login/styles/LogoWrapper';
import { getSavedLogin } from '@/utils/functions/loginUtils';
import { useObservableLocalStorage } from '@/utils/hooks/useLocalStorage';
import { routePaths } from '@/utils/routePaths';
import { USER_TYPE } from '@/utils/types';

export const INNER_LOGIN_NAVIGATION = 'inner-login-navigation';
export const REDIRECT_URL = 'redirect-url';

const Login = () => {
  const router = useRouter();
  const [, setIsSent] = useObservableLocalStorage('downgrade-message-sent');

  useEffect(() => {
    const login = getSavedLogin();
    if (!login) return;
    if (login === USER_TYPE.ADMIN) {
      localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
      router.push(routePaths.login);
    }
  }, [router]);

  return (
    <LoginWrapper>
      <LoginContainer>
        <Grid
          sx={{ width: '100%', maxWidth: '1130px' }}
          display="flex"
          alignItems="center"
          flexDirection="column"
        >
          <LogoWrapper>
            <Logo />
          </LogoWrapper>
          <Typography variant="h1" sx={{ mt: 7.5, mb: 1.5 }}>
            Ostana Admin Panel
          </Typography>
          <Typography variant="p1" sx={{ mb: 5 }}>
            Track all business data and their current statuses.
          </Typography>
          <Grid display="flex" justifyContent="center" gap={2.5} container>
            <LoginItemWrapper
              onClick={() => {
                localStorage.setItem(INNER_LOGIN_NAVIGATION, 'true');
                router.push(routePaths.login);
                setIsSent(false);
              }}
              sx={{
                backgroundImage: 'url("/images/signInBg.jpeg")',
                backgroundPosition: 'top -60px right 0',
                backgroundSize: '138.815% 82.892%',
                backgroundColor: 'lightgray',
                backgroundRepeat: 'no-repeat',
              }}
            >
              <Grid display="flex" flexDirection="column" sx={{ zIndex: 10 }}>
                <Typography
                  variant="h4Bold"
                  color="white.main"
                  sx={{ textAlign: 'center', mb: 0.5 }}
                >
                  Admin
                </Typography>
                <Typography variant="p2" color="system.inputBorder">
                  Manage business registered on the platform.
                </Typography>
              </Grid>
              <CommonButton
                data-testid="business-sign-in"
                sx={{ width: '180px', zIndex: 10 }}
              >
                Sign In
              </CommonButton>
            </LoginItemWrapper>
          </Grid>
        </Grid>
      </LoginContainer>
      <Footer authScreen />
    </LoginWrapper>
  );
};

export default Login;
